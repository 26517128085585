import React from 'react';
import MainContent from './components/main-content';
import Layout from '../../components/layout';
import NegoButton from '../../components/nego-button';
import { useNegotiationData } from './hooks/useNegotiation';
import useAuth from '../../app/hooks/useAuth';
import useStyles from './styles';

const Home = () => {
  const classes = useStyles();
  const negotiation = useNegotiationData();
  const { orgId, email, username } = useAuth();
  return (
    <Layout>
      <MainContent />
      <NegoButton
        className={classes.negoButton}
        orgId={orgId}
        email={email}
        username={username}
        product={negotiation}
      />
    </Layout>
  );
};

export default Home;
