import { TYPES } from '../constants';

export const initialState = {
  negotiation: undefined,
  cart: {},
};

export default function cartReducer(state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case TYPES.ADD_PRODUCT: {
      const { id } = payload;
      const { cart } = state;
      const products = cart[id] ? cart[id] : [];
      return {
        ...state,
        cart: { ...cart, [id]: [...products, payload] },
      };
    }
    case TYPES.REMOVE_PRODUCT: {
      const { id } = payload;
      const { cart } = state;
      const products = cart[id] ? cart[id] : [];
      products.shift();
      const newCart = { ...cart, [id]: [...products] };
      if (products.length === 0) {
        delete newCart[id];
      }
      return {
        ...state,
        cart: newCart,
      };
    }
    case TYPES.SET_CART_NEGOTIATION:
      return {
        ...state,
        negotiation: action.payload,
      };
    default:
      return state;
  }
}
