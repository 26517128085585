import * as constants from '../constants';

const login = ({ orgId, email, username }) => ({
  type: constants.TYPES.LOGIN,
  payload: {
    orgId,
    email,
    username,
  },
});

export { login };
