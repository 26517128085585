import React from 'react';
import { Card, Row, Col } from 'antd';
import useStyles from './styles';

const PromotionList = ({ categories }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.title}>Browse By Category</div>
      <Row gutter={40} type="flex" align="middle" justify="center">
        {categories.map((category) => {
          return (
            <Col xs={24} sm={6}>
              <Card
                style={{ width: '100%' }}
                cover={<img alt="example" src={category.url} />}
                hoverable
              >
                <Card.Meta description={category.name} />
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default PromotionList;
