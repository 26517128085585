import React from 'react';
import { List, Avatar, Typography, Button, Row, InputNumber } from 'antd';
import Layout from '../../components/layout';
import NegoButton from '../../components/nego-button';
import useStyles from './styles';
import { useCart, useAddProduct, useRemoveProduct } from './hooks/useCart';
import { useCartNegotiation, useCartNegotiationData } from './hooks/useNegotiation';
import useAuth from '../../app/hooks/useAuth';

const Cart = () => {
  const classes = useStyles();
  const { orgId, email, username } = useAuth();
  const cart = useCart();
  const addProduct = useAddProduct();
  const removeProduct = useRemoveProduct();
  const list = Object.keys(cart).map((key) => {
    const item = {
      ...cart[key][0],
      quantity: cart[key].length,
    };
    return item;
  });
  const negotiation = useCartNegotiationData();
  const setCartNegotiaton = useCartNegotiation();
  const onClickNegotiateButton = () => {
    setCartNegotiaton();
  };
  const onUpdateQuantity = (item, value) => {
    if (value > item.quantity) {
      addProduct(item);
    } else {
      removeProduct(item);
    }
  };

  return (
    <Layout>
      <div className={classes.root}>
        <Row type="flex" justify="center">
          <Typography.Title>Cart</Typography.Title>
        </Row>
        <List
          className={classes.list}
          itemLayout="horizontal"
          dataSource={list}
          renderItem={(item) => (
            <List.Item
              key={item.id}
              extra={
                <InputNumber
                  value={item.quantity}
                  onChange={(value) => {
                    onUpdateQuantity(item, value);
                  }}
                />
              }
            >
              <List.Item.Meta
                avatar={<Avatar src={item.image_url} />}
                title={`${item.id} - ${item.name}`}
                description={item.description}
              />
            </List.Item>
          )}
        />
        <Row type="flex" justify="center" gutter={-20}>
          <Button disabled={cart.length === 0}>Checkout</Button>
          <div className={classes.space} />
          <Button type="primary" disabled={cart.length === 0} onClick={onClickNegotiateButton}>
            Negotiate
          </Button>
        </Row>
      </div>
      <NegoButton
        className={classes.negoButton}
        orgId={orgId}
        email={email}
        username={username}
        product={negotiation}
      />
    </Layout>
  );
};

export default Cart;
