import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../redux/actions';
import * as selectors from '../redux/selectors';

export const useCart = () => {
  return useSelector(selectors.getCart);
};

export const useAddProduct = () => {
  const dispatch = useDispatch();
  return (product) => {
    dispatch(actions.addProduct(product));
  };
};

export const useRemoveProduct = () => {
  const dispatch = useDispatch();
  return (product) => {
    dispatch(actions.removeProduct(product));
  };
};
