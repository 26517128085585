/* eslint-disable import/prefer-default-export */
import * as constants from '../constants';

export const addProduct = (product) => ({
  type: constants.TYPES.ADD_PRODUCT,
  payload: product,
});

export const removeProduct = (product) => ({
  type: constants.TYPES.REMOVE_PRODUCT,
  payload: product,
});

export const setCartNegotiation = (data) => ({
  type: constants.TYPES.SET_CART_NEGOTIATION,
  payload: data,
});
