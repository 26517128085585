import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Button } from 'antd';
import useStyles from './styles';
import Product from '../product';

const ProductList = ({ products }) => {
  const settings = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  const classes = useStyles();
  const sliderRef = useRef(null);
  return (
    <div className={classes.root}>
      <div className={classes.title}> What's New</div>
      <div className={classes.sliderContainer}>
        <Button
          shape="circle"
          icon="left"
          onClick={() => {
            sliderRef.current.slickPrev();
          }}
        />
        <div className={classes.slider}>
          <Slider ref={sliderRef} {...settings}>
            {products &&
              products.map((product) => {
                return <Product {...product} />;
              })}
          </Slider>
        </div>
        <Button
          shape="circle"
          icon="right"
          onClick={() => {
            sliderRef.current.slickNext();
          }}
        />
      </div>
    </div>
  );
};

export default ProductList;
