/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useRef } from 'react';
import qs from 'query-string';
import { Spin, Icon } from 'antd';
import configs from '../../configs';
import useStyles from './styles';

const userList = {
  ani: {
    avatar: 'https://ca.slack-edge.com/TG2617AP2-UK9SFAGAF-18ed62654c56-512',
    name: 'Aniruddha Ganguly',
  },
  huynh: {
    avatar:
      'https://web.whatsapp.com/pp?e=https%3A%2F%2Fpps.whatsapp.net%2Fv%2Ft61.24694-24%2F61467252_2209676462414757_626189103427223552_n.jpg%3Foe%3D5ECB8EDF%26oh%3D2ef64e8a12c31850079ccaa502521529&t=l&u=84337552807%40c.us&i=1559702461&n=qC0UTTTeJSUOS9FwcbgNNz54SIkzMwf9bBK2DhzUhkY%3D',
    name: 'Huynh Nguyen',
  },
  man: {
    avatar: 'https://f11-org-zp.zdn.vn/d33c1642e4870dd95496.jpg',
    name: 'Man Nguyen',
  },
  shashank: {
    avatar: 'https://files.slack.com/files-pri/TG2617AP2-F0127Q2L74K/dsc_0022-min.jpg',
    name: 'Shashank Modi',
  },
};

const NegoIframe = ({ orgId, email, username, product }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const refIframe = useRef(null);
  const orgInfo = configs.ORG_INFO[orgId];
  useEffect(() => {
    if (!refIframe) {
      return;
    }
    const data = {
      organisation_id: orgInfo && orgInfo.orgId,
      product_id: product ? product.id : '',
      customer_name: username,
      customer_email: email,
      text: 'Product Enquiry',
      type: 'MessageInput',
      user: userList[username]
        ? userList[username]
        : {
            avatar: 'https://pickaface.net/gallery/avatar/20151205_194059_2696_Chat.png',
            username,
          },
    };
    refIframe.current.contentWindow.postMessage(JSON.stringify(data), '*');
  }, [orgId, email, username, product, loading]);

  const params = qs.stringify({
    apiUrl: configs.SVC_GCP,
    apiKey: orgInfo && orgInfo.apiKey,
    identity: email,
  });
  const iframeUrl = `${configs.CHAT_IFRAME}?${params}`;

  return (
    <div className={classes.root}>
      <Spin
        tip="Loading chat..."
        spinning={loading}
        indicator={
          <Icon
            type="loading"
            style={{
              fontSize: 24,
            }}
            spin
          />
        }
      >
        <iframe
          className={classes.iframe}
          title="nego"
          ref={refIframe}
          src={iframeUrl}
          onLoad={() => {
            setLoading(false);
          }}
        />
      </Spin>
    </div>
  );
};

export default NegoIframe;
