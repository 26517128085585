/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getNegotiation as selectorGetNegotiation } from '../redux/selectors';
import { setNegotiation as actionSetNegotiation } from '../redux/actions';

export const useNegotiation = () => {
  const dispatch = useDispatch();
  return (product) => {
    dispatch(actionSetNegotiation(product));
  };
};

export const useNegotiationData = () => {
  const setNegotiation = useNegotiation();
  useEffect(() => {
    return () => {
      setNegotiation(undefined);
    };
  }, []);
  return useSelector(selectorGetNegotiation);
};
