import { TYPES } from '../constants';

export const initialState = {
  orgId: '',
  email: '',
  username: '',
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case TYPES.LOGIN:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
