/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { Icon, Menu, PageHeader, Button, Divider, Badge, Input } from 'antd';
import { Link } from 'react-router-dom';
import logo from '../../../assets/img/negobot-logo-dark.png';
import { useCart } from '../../../features/cart/hooks/useCart';
import history from '../../../history';
import useStyles from './styles';

const categories = [
  'Beef',
  'Diary,Dessert,Pastry',
  'Deli',
  'Finger Food',
  'Fruits And Vegetables',
  'Lamb/Mutton',
  'Pork',
  'Poultry',
  'Seafood',
  'Specialty Items',
];
const { SubMenu } = Menu;
const { Search } = Input;

const HeaderContent = () => {
  const classes = useStyles();
  const cart = useCart();
  let cartLength = 0;
  Object.keys(cart).forEach((key) => {
    cartLength += cart[key].length;
  });

  const onClickAddToCartButton = () => {
    history.push('/cart');
  };

  const onClickLogo = () => {
    history.push('/home');
  };

  return (
    <div>
      <PageHeader
        className={classes.pageHeader}
        extra={[
          <Button className={classes.wishlistButton} type="link">
            <Badge
              count={0}
              showZero
              offset={[0, 20]}
              style={{ backgroundColor: '#949494', boxShadow: 'none' }}
            >
              <Icon type="heart" theme="filled" style={{ fontSize: '20px', color: 'white' }} />
            </Badge>
          </Button>,
          <Button className={classes.cartButton} type="link" onClick={onClickAddToCartButton}>
            <Badge
              count={cartLength}
              showZero
              offset={[0, 20]}
              style={{ backgroundColor: '#949494', boxShadow: 'none' }}
            >
              <Icon type="shopping" theme="filled" style={{ fontSize: '20px', color: 'white' }} />
            </Badge>
            item - $0.00
          </Button>,
          <Divider type="vertical" />,
          <Button className={classes.loginButton} type="link">
            <Icon type="user" />
            {`Login `}
          </Button>,
        ]}
      />
      <div className={classes.menuContainer}>
        <Link to="/home">
          <img className={classes.logoImage} alt="Negobot Logo" src={logo} />
        </Link>
        <Menu mode="horizontal">
          <Menu.Item key="aboutUs">ABOUT US</Menu.Item>
          <SubMenu
            title={
              <span>
                SHOP PRODUCTS
                <Icon type="caret-down" />
              </span>
            }
          >
            {categories.map((category) => {
              return <Menu.Item onClick={onClickLogo}>{category}</Menu.Item>;
            })}
          </SubMenu>
          <Menu.Item key="whatsNew">WHATS NEW</Menu.Item>
          <Menu.Item key="recipes">RECIPES</Menu.Item>
        </Menu>
        <div className={classes.rhsContainer}>
          <Button type="primary">CAFE</Button>
          <Search
            placeholder="search here ..."
            onSearch={(value) => console.log(value)}
            style={{ width: 200 }}
          />
        </div>
      </div>
    </div>
  );
};

export default HeaderContent;
