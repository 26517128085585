const config = {
  ENV: 'dev',
  SVC_GCP: 'https://api-dev.negobot.co',
  CHAT_IFRAME: 'https://dev.buyer.iframe.negobot.co',
  ORG_INFO: {
    angliss: {
      orgId: 'angliss',
      apiKey: 'navqkpejlbfmssyufbcq',
    },
    'angliss-mul': {
      orgId: 'angliss-mul',
      apiKey: 'fpfantounebfkmyuiywf',
    },
    'angliss-bas': {
      orgId: 'angliss-bas',
      apiKey: 'jzhkwxfdjyqvmpqtfjud',
    },
  },
};

export default config;
