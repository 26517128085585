/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Form, Input, Button, Row, Col, Typography, Select } from 'antd';
import useStyles from './styles';

const { Title } = Typography;

const LoginForm = ({ form, onSubmit }) => {
  const classes = useStyles();
  useEffect(() => {
    form.validateFields();
  }, []);

  const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = form;

  const usernameError = isFieldTouched('username') && getFieldError('username');
  const emailError = isFieldTouched('email') && getFieldError('email');
  const botTypeError = isFieldTouched('orgId') && getFieldError('orgId');

  const hasErrors = (fieldsError) => {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        onSubmit(values);
      }
    });
  };

  return (
    <div className={classes.root}>
      <Form className={classes.form} onSubmit={handleSubmit}>
        <Title level={4}>Login</Title>
        <Row gutter={20}>
          <Col>
            <Form.Item
              label="Bot type"
              validateStatus={botTypeError ? 'error' : ''}
              help={botTypeError || ''}
            >
              {getFieldDecorator('orgId', {
                rules: [
                  {
                    required: true,
                    message: 'Please select the bot type',
                  },
                ],
              })(
                <Select>
                  <Select.Option value="angliss">Single</Select.Option>
                  <Select.Option value="angliss-mul">Multi</Select.Option>
                  <Select.Option value="angliss-bas">Basket</Select.Option>
                </Select>,
              )}
            </Form.Item>
            <Form.Item
              label="E-mail"
              validateStatus={emailError ? 'error' : ''}
              help={emailError || ''}
            >
              {getFieldDecorator('email', {
                rules: [
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                  {
                    required: true,
                    message: 'Please input your E-mail!',
                  },
                ],
              })(<Input />)}
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Username"
              validateStatus={usernameError ? 'error' : ''}
              help={usernameError || ''}
            >
              {getFieldDecorator('username', {
                rules: [{ required: true, message: 'Please input your username!' }],
              })(<Input placeholder="What shall we call you?" />)}
            </Form.Item>
          </Col>
        </Row>
        <Button type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())}>
          Login
        </Button>
      </Form>
    </div>
  );
};

export default Form.create({ name: 'loginForm' })(LoginForm);
