import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import history from './history';
import appReducer from './app/redux/appReducers';
import authReducer from './app/redux/authReducers';
import homeReducer from './features/home/redux/reducers';
import cartReducer from './features/cart/redux/reducers';

export default combineReducers({
  router: connectRouter(history),
  appReducer,
  authReducer,
  homeReducer,
  cartReducer,
});
