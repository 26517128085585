import React, { useState, useEffect } from 'react';
import { Popover, Button, Drawer, Avatar } from 'antd';
import NegoIframe from '../nego-iframe';
import useStyles from './styles';

const NegoButton = ({ className, orgId, email, username, product }) => {
  const [popOverVisibility, setPopOverVisibility] = useState(true);
  const [drawerVisibility, setDrawerVisibility] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (product) {
      setDrawerVisibility(true);
    } else {
      setDrawerVisibility(false);
    }
  }, [product]);

  const getPopupContainer = (triggerNode) => {
    return triggerNode;
  };

  const handleButtonClick = () => {
    setPopOverVisibility(false);
    setDrawerVisibility(!drawerVisibility);
  };

  return (
    <div className={className}>
      <Popover
        title={`Hi ${username}, My name is Sophia`}
        content="How can I help you?"
        placement="topRight"
        visible={popOverVisibility}
        getPopupContainer={getPopupContainer}
      >
        <Button type="link" shape="circle" size="large" onClick={handleButtonClick}>
          <Avatar
            src="https://storage.googleapis.com/download/storage/v1/b/nego-dev.appspot.com/o/products%2F1579251503520.png?generation=1579251503885163&alt=media"
            onClick={handleButtonClick}
          />
        </Button>
      </Popover>
      <Drawer
        className={classes.drawer}
        visible={drawerVisibility}
        maskClosable
        closable
        width={window.innerWidth / 2}
        onClose={handleButtonClick}
      >
        <NegoIframe orgId={orgId} email={email} username={username} product={product} />
      </Drawer>
    </div>
  );
};

export default NegoButton;
