import React from 'react';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import history from './history';
import configStore from './config-store';
import PrivateRoute from './components/private-route';
import Login from './features/login';
import Home from './features/home';
import Cart from './features/cart';
import './utils/sentry';
import './utils/logrocket';

const store = configStore();

const AppLoader = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route exact path="/" component={Login} />
        <PrivateRoute exact path="/home" component={Home} />
        <PrivateRoute exact path="/cart" component={Cart} />
      </Switch>
    </ConnectedRouter>
  </Provider>
);

export default AppLoader;
