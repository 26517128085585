import React from 'react';
import { Layout as AntdLayout } from 'antd';
import HeaderContent from './header-content';
import FooterContent from './footer-content';
import useStyles from './styles';

const { Header, Content, Footer } = AntdLayout;
const Layout = ({ children }) => {
  const classes = useStyles();
  return (
    <AntdLayout className={classes.root}>
      <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
        <HeaderContent />
      </Header>
      <Content style={{ marginTop: 112 }}>{children}</Content>
      <Footer>
        <FooterContent />
      </Footer>
    </AntdLayout>
  );
};

export default Layout;
