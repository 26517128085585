import React from 'react';
import { Row, Col, Typography, Icon, Input, Button } from 'antd';
import logo from '../../../assets/img/negobot-logo-dark.png';
import useStyles from './styles';

const { Text, Title } = Typography;
const FooterContent = () => {
  const classes = useStyles();
  return (
    <Row gutter={20}>
      <Col span={8}>
        <Row style={{ marginBottom: 60 }}>
          <img alt="Negobot Logo" src={logo} width={135} />
        </Row>
        <Row type="flex" gutter={20} style={{ marginBottom: 60 }}>
          <Col>
            <Icon type="phone" style={{ fontSize: 40 }} />
          </Col>
          <Col>
            <Text>Got Questions?</Text>
            <br />
            <Text>+65 98633959</Text>
          </Col>
        </Row>
        <Row>
          <Title level={4}> Newsletter Signup</Title>
          <Input.Search placeholder="Your email here..." enterButton="SIGN UP" size="large" />
        </Row>
      </Col>
      <Col span={8}>
        <Title level={4} style={{ color: '#bba06b', paddingLeft: 15 }}>
          Useful Links
        </Title>
        {[
          'Terms and Conditions',
          'Security & Privacy Policy',
          'About Us',
          'Recipes',
          'Whats New',
        ].map((text) => {
          return (
            <div>
              <Button className={classes.usefulButton} type="link">
                {text}
              </Button>
            </div>
          );
        })}
      </Col>
      <Col span={8}>
        <Title level={3}>Negobot Singapore Pte Ltd</Title>
        <Row type="flex" align="middle" gutter={20} style={{ marginBottom: 20 }}>
          <Col>
            <Icon type="environment" />
          </Col>
          <Col>
            <Text>
              Sultan Link, 57 Mohamed Sultan Road <br />
              #01-05, Singapore 238997
            </Text>
          </Col>
        </Row>
        <Row type="flex" align="middle" gutter={20} style={{ marginBottom: 20 }}>
          <Col>
            <Icon type="mobile" />
          </Col>
          <Col>
            <Text>+65 98633959</Text>
          </Col>
        </Row>
        <Row type="flex" align="middle" gutter={20} style={{ marginBottom: 20 }}>
          <Col>
            <Icon type="mail" />
          </Col>
          <Col>
            <Text>Email: hello@negobot.co</Text>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default FooterContent;
