import configs from '../../configs';

export const APIS = {
  GET_PRODUCTS: `${configs.SVC_GCP}/data/products/publish`,
};

export const TYPES = {
  GET_PRODUCTS: 'GET_PRODUCTS',
  GET_PRODUCTS_SUCCESS: 'GET_PRODUCTS_SUCCESS',
  GET_PRODUCTS_FAILURE: 'GET_PRODUCTS_FAILURE',
  PARSE_PRODUCTS: 'PARSE_PRODUCTS',
  SET_NEGOTIATION: 'SET_NEGOTIATION',
};
