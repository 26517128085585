import { createUseStyles } from 'react-jss';
import * as variables from '../../../styles/variables';

const useStyles = createUseStyles({
  pageHeader: {
    padding: [5, 24],
    background: variables.primaryColor,
  },
  loginButton: {
    color: 'white',
  },
  cartButton: {
    color: 'white',
    '@global': {
      '.ant-badge': {
        marginRight: 14,
      },
    },
  },
  wishlistButton: {
    color: 'white',
    padding: 0,
  },
  menuContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: [0, 24],
    background: 'white',
    '@global': {
      '.ant-menu': {
        borderBottom: 'none',
      },
    },
  },
  logoImage: {
    height: 70,
    margin: 10,
  },
  rhsContainer: {
    display: 'flex',
  },
});

export default useStyles;
