import React from 'react';
import { Card, Row, Col } from 'antd';
import useStyles from './styles';

const PromotionList = ({ promotions }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.title}>Promotions</div>
      <Row gutter={20} type="flex" align="middle" justify="center">
        {promotions.map((promotion) => {
          return (
            <Col span={4}>
              <Card
                style={{ width: '100%' }}
                bodyStyle={{ display: 'none' }}
                cover={<img alt="example" src={promotion} />}
                hoverable
              />
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default PromotionList;
