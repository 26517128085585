/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as selectors from '../redux/selectors';
import * as actions from '../redux/actions';

export const useCartNegotiation = () => {
  const dispatch = useDispatch();
  return (product) => {
    dispatch(actions.setCartNegotiation(product));
  };
};

export const useCartNegotiationData = () => {
  const setCartNegotiation = useCartNegotiation();
  useEffect(() => {
    return () => {
      setCartNegotiation(undefined);
    };
  }, []);
  return useSelector(selectors.getCartNegotiation);
};
